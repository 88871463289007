// BookFlightMapController.js

import SidebarMapController from './SidebarMapController.js';
import { MapUtils } from './MapUtils.js';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';

export default class BookFlightMapController extends SidebarMapController {
    constructor(containerId, $wire, componentEl) {
        super(containerId, $wire, componentEl);
        this.markersData = {};
        this.jumpseatEnabled = false;
        this.selectedDestination = null;
        this.currentRouteData = null;
        this.requestId = 0;

        // Sidebar elements
        this.sidebarElements = this.getSidebarElements();

        this.setupEventListeners();
        this.setupButtonEventHandlers();
    }

    /**
     * On map ready, fetch data and add markers.
     */
    async onMapReady() {
        try {
            await this.getBookFlightMapData();
            this.addMarkersToMap();
            this.hideSpinner(); // Hide spinner right before initiating flyTo
            this.fitMapToMarkers(this.getAllMarkers());
            this.updateSidebarOnLoad();
        } catch (error) {
            console.error('Error during map data loading:', error);
            this.hideSpinner(); // Hide spinner in case of error
        }
    }

    /**
     * On map style updated, re-add markers and route line.
     */
    onMapStyleUpdated() {
        this.addMarkersToMap();
        this.fitMapToMarkers(this.getAllMarkers());

        // Re-add the route line if it exists
        if (this.currentRouteData) {
            this.drawRouteLine(this.currentRouteData);
        }
    }

    /**
     * Get references to sidebar elements.
     */
    getSidebarElements() {
        return {
            sidebar: document.getElementById('sidebar'),
            departure: document.getElementById('data-departureAirport'),
            arrival: document.getElementById('data-arrivalAirport'),
            loadingIndicator: document.getElementById('sidebar-loadingIndicator'),
            content: document.getElementById('sidebar-content'),
            routeDataContainer: document.getElementById('sidebar-route-data'),
            routeData: {
                aircraftTypes: document.getElementById('data-aircraftTypes'),
                operators: document.getElementById('data-operators'),
                ete: document.getElementById('data-ETE'),
                distance: document.getElementById('data-distance')
            },
            buttons: {
                booking: document.getElementById('booking-button'),
                bookingSelf: document.getElementById('booking-self-button'),
                jumpseat: document.getElementById('jumpseat-button')
            },
            dataButtons: document.getElementById('data-buttons')
        };
    }

    /**
     * Set up event listeners.
     */
    setupEventListeners() {
        // Livewire data updates
        this.componentEl.addEventListener('mapDataUpdated', (event) => {
            this.handleMapDataUpdated(event.detail);
        });

        // Random Destination Button
        const randomButton = document.getElementById('random-destination-button');
        if (randomButton) {
            randomButton.addEventListener('click', () => {
                this.selectRandomDestination();
            });
        }

        this.componentEl.addEventListener('start-map-loading', () => {
            this.showSpinner();
            this.resetMapToInitialState();
            this.getBookFlightMapData().then(() => {
                this.addMarkersToMap();
                this.fitMapToMarkers(this.getAllMarkers());
                this.hideSpinner();
            });
        });

        this.componentEl.addEventListener('selected-airport', (event) => {
            const airportId = event.detail[0];
            if (!airportId) {
                console.error('No airportId provided in selected-airport event.');
                return;
            }

            // Convert airportId to string for comparison
            const airportIdStr = airportId.toString();

            // Get the list of layers in the map's current style
            const styleLayers = this.map.getStyle().layers;
            const validLayers = ['markers', 'jumpseatMarkers'].filter((layer) =>
                styleLayers.some((styleLayer) => styleLayer.id === layer)
            );

            if (validLayers.length > 0) {
                // Find the feature in the map's source for only the valid layers
                const features = this.map.queryRenderedFeatures({
                    layers: validLayers,
                    filter: ['==', ['to-string', ['get', 'id']], airportIdStr],
                });

                if (features.length > 0) {
                    const feature = features[0];
                    this.handleFeatureClick(feature);
                } else {
                    console.warn(`No feature found for airportId ${airportId}`);
                }
            } else {
                console.warn('No valid layers found to query features.');
            }
        });

        // Close Button
        const closeButton = document.getElementById('sidebar-close');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                this.deselectDestination();
            });
        }
    }

    /**
     * Setup event handlers for buttons.
     */
    setupButtonEventHandlers() {
        const { booking, bookingSelf, jumpseat } = this.sidebarElements.buttons;

        if (booking) {
            booking.addEventListener('click', () => this.doBooking(false));
        }

        if (bookingSelf) {
            bookingSelf.addEventListener('click', () => this.doBooking(true));
        }

        if (jumpseat) {
            jumpseat.addEventListener('click', () => this.doJumpseat());
        }
    }

    /**
     * Handle feature click events.
     */
    handleFeatureClick(feature) {
        const { id: destinationId, type: featureType } = feature.properties;
        const selectedDestination = {
            id: destinationId,
            name: feature.properties.name,
            identifiers: feature.properties.identifiers,
            countryCode: feature.properties.countryCode || 'us'
        };

        // Store selected destination
        this.selectedDestination = selectedDestination;

        // Update arrival airport info
        this.updateArrivalAirportInfo(selectedDestination);

        // Hide all buttons initially
        this.hideAllButtons();
        this.showLoadingState();

        // Increment the request ID
        const currentRequestId = ++this.requestId;

        if (featureType === 'destinationAirport') {
            this.handleDestinationAirportClick(destinationId, currentRequestId);
        } else if (featureType === 'jumpseatLocation') {
            this.handleJumpseatLocationClick();
        }
    }

    /**
     * Handle destination airport click.
     */
    handleDestinationAirportClick(destinationId, currentRequestId) {
        const { booking, bookingSelf, jumpseat } = this.sidebarElements.buttons;
        const currentId = this.markersData.currentPilotLocation.id;
        const isCurrentLocation = destinationId === currentId;

        if (isCurrentLocation) {
            this.toggleButtonVisibility(bookingSelf, true);
            // Do not show Jumpseat button when selecting current location
        } else {
            this.toggleButtonVisibility(booking, true);
            if (this.jumpseatEnabled) {
                this.toggleButtonVisibility(jumpseat, true);
            }
        }

        // Call Livewire method to get route data
        if (this.$wire) {
            this.$wire.airportRouteQuery(currentId, destinationId).then((data) => {
                // Check if the request ID matches
                if (currentRequestId === this.requestId) {
                    this.hideLoadingState();
                    this.updateRouteData(data);
                    this.drawRouteLine(data.routing);
                    this.currentRouteData = data.routing; // Store the routing data
                } else {
                    // Outdated response, do not process
                }
            }).catch((error) => {
                if (currentRequestId === this.requestId) {
                    console.error('Error fetching route data:', error);
                    this.hideLoadingState();
                }
                // Else, ignore the error
            });
        }
    }

    /**
     * Handle jumpseat location click.
     */
    handleJumpseatLocationClick() {
        const { jumpseat } = this.sidebarElements.buttons;
        if (this.jumpseatEnabled) {
            this.toggleButtonVisibility(jumpseat, true);
        }
        this.removeRouteLine();
        this.hideLoadingState();
        this.hideRouteData();
    }

    /**
     * Fetch marker data using Livewire.
     */
    async getBookFlightMapData() {
        if (this.$wire) {
            try {
                const data = await this.$wire.gatherData();
                this.markersData = {
                    currentPilotLocation: data.currentPilotLocation,
                    destinationAirports: data.destinationAirports,
                    favoriteAirports: data.favoriteAirports,
                    jumpseatLocations: data.jumpseatLocations
                };
                this.jumpseatEnabled = data.jumpseatEnabled;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        } else {
            throw new Error('$wire is not available.');
        }
    }

    /**
     * Add markers to the map.
     */
    addMarkersToMap() {
        if (!this.mapReady) {
            console.warn('Map is not ready. Deferring addMarkersToMap().');
            return;
        }

        this.removeExistingLayersAndSources(['markers', 'jumpseatMarkers']);

        if (this.jumpseatEnabled && Object.keys(this.markersData.jumpseatLocations).length > 0) {
            const jumpseatGeoJSON = this.prepareJumpseatGeoJSON();
            this.addLayer('jumpseatMarkers', jumpseatGeoJSON, 'jumpseatMarkers');
        }

        const mainGeoJSON = this.prepareMainGeoJSON();
        this.addLayer('markers', mainGeoJSON, 'markers');
    }

    /**
     * Prepare GeoJSON data for main markers.
     */
    prepareMainGeoJSON() {
        const features = [];
        const destinationAirports = Object.values(this.markersData.destinationAirports || {});
        const favoriteAirports = Object.values(this.markersData.favoriteAirports || {});
        const pilotLocation = this.markersData.currentPilotLocation;

        // Remove currentPilotLocation from destinationAirports if present
        const filteredDestinations = destinationAirports.filter(airport => airport.id !== pilotLocation.id);

        // Add destination airports
        filteredDestinations.forEach(airport => {
            const iconType = this.getIconType(airport, favoriteAirports);
            features.push(MapUtils.createFeature(airport, 'destinationAirport', iconType));
        });

        // Add current pilot location
        if (pilotLocation && pilotLocation.lon && pilotLocation.lat) {
            const isDestination = destinationAirports.some(dest => dest.id === pilotLocation.id);
            const iconType = this.getIconType(pilotLocation, favoriteAirports, true);
            const featureType = isDestination ? 'destinationAirport' : 'currentPilotLocation';

            features.push(MapUtils.createFeature(pilotLocation, featureType, iconType));
        }

        return {
            type: 'FeatureCollection',
            features: features
        };
    }

    /**
     * Prepare GeoJSON data for jumpseat locations.
     */
    prepareJumpseatGeoJSON() {
        const features = [];
        const jumpseatLocations = Object.values(this.markersData.jumpseatLocations || {});
        const favoriteAirports = Object.values(this.markersData.favoriteAirports || {});

        jumpseatLocations.forEach(jumpseat => {
            const isFavorite = favoriteAirports.some(fav => fav.id === jumpseat.id);
            const iconType = isFavorite ? 'marker_jumpseat_favorite' : 'marker_jumpseat';
            features.push(MapUtils.createFeature(jumpseat, 'jumpseatLocation', iconType));
        });

        return {
            type: 'FeatureCollection',
            features: features
        };
    }

    /**
     * Get all markers for fitting the map.
     */
    getAllMarkers() {
        return [
            this.markersData.currentPilotLocation,
            //...Object.values(this.markersData.destinationAirports || {}),
            //...Object.values(this.markersData.jumpseatLocations || {})
        ];
    }

    /**
     * Handle map data updates from Livewire.
     */
    handleMapDataUpdated(data) {
        this.markersData = {
            currentPilotLocation: data.currentPilotLocation,
            destinationAirports: data.destinationAirports,
            favoriteAirports: data.favoriteAirports,
            jumpseatLocations: data.jumpseatLocations
        };
        this.jumpseatEnabled = data.jumpseatEnabled;

        if (this.mapReady) {
            this.showSpinner();
            this.addMarkersToMap();
            this.fitMapToMarkers(this.getAllMarkers());
            this.hideSpinner();
        } else {
            // Wait until the map is ready
            this.map.on('load', () => {
                this.showSpinner();
                this.addMarkersToMap();
                this.fitMapToMarkers(this.getAllMarkers());
                this.hideSpinner();
            });
        }
    }

    /**
     * Deselect the selected destination and reset the sidebar.
     */
    deselectDestination() {
        // Clear selected destination
        this.selectedDestination = null;
        this.currentRouteData = null; // Clear route data to prevent re-drawing
        this.requestId = 0; // Reset the request ID

        // Clear arrival airport info
        if (this.sidebarElements.arrival) {
            this.sidebarElements.arrival.innerHTML = '';
        }

        // Hide route data and buttons
        this.hideRouteData();
        this.hideAllButtons();

        // Remove route line
        this.removeRouteLine();
    }

    /**
     * Handle the jumpseat action.
     */
    doJumpseat() {
        if (!this.selectedDestination || !this.selectedDestination.id) {
            console.error('No destination selected for jumpseat.');
            return;
        }

        // Show loading state
        this.showLoadingState();

        // Clear route data and reset request ID
        this.currentRouteData = null;
        this.requestId = 0; // Reset the request ID

        // Call Livewire method
        const destinationId = this.selectedDestination.id;
        if (this.$wire) {
            this.$wire.jumpseatToLocation(destinationId).then(() => {
                // Reinitialize map data
                this.getBookFlightMapData().then(() => {
                    this.addMarkersToMap();
                    //this.fitMapToMarkers(this.getAllMarkers());
                    this.resetMapToInitialState();
                    this.hideLoadingState();
                });
            }).catch((error) => {
                console.error('Error during jumpseat:', error);
                this.hideLoadingState();
            });
        } else {
            console.error('$wire is not available.');
            this.hideLoadingState();
        }
    }

    /**
     * Handle the booking action.
     */
    doBooking(isSelfBooking = false) {
        const currentId = this.markersData.currentPilotLocation.id;
        const destinationId = isSelfBooking ? currentId : (this.selectedDestination ? this.selectedDestination.id : null);

        if (!destinationId) {
            console.error('No destination selected for booking.');
            return;
        }

        // Show loading state
        this.showLoadingState();

        // Call Livewire method
        if (this.$wire) {
            this.$wire.createDispatch(currentId, destinationId).then(() => {
                // The Livewire method should handle the redirect
            }).catch((error) => {
                console.error('Error during booking:', error);
                this.hideLoadingState();
            });
        } else {
            console.error('$wire is not available.');
            this.hideLoadingState();
        }
    }

    /**
     * Select a random destination.
     */
    selectRandomDestination() {
        const destinations = Object.values(this.markersData.destinationAirports || {});
        if (destinations.length > 0) {
            const randomIndex = Math.floor(Math.random() * destinations.length);
            const randomDestination = destinations[randomIndex];

            // Find the feature in the map's source
            const features = this.map.queryRenderedFeatures({
                layers: ['markers'],
                filter: ['==', ['to-string', ['get', 'id']], randomDestination.id.toString()]
            });

            if (features.length > 0) {
                const feature = features[0];
                this.handleFeatureClick(feature);
            }
        }
    }

    /**
     * Update the sidebar on initial load.
     */
    updateSidebarOnLoad() {
        const { sidebar, loadingIndicator, content, routeDataContainer, departure, buttons } = this.sidebarElements;

        if (sidebar) {
            sidebar.classList.remove('hidden');
            loadingIndicator.style.display = 'none';
            content.style.display = 'block';

            // Hide route data on initial load
            routeDataContainer.style.display = 'none';

            const originAirport = this.markersData.currentPilotLocation;
            const destinationAirports = Object.values(this.markersData.destinationAirports || {});

            if (originAirport) {
                // Update departure airport info
                departure.innerHTML = MapUtils.generateAirportInfoHTML(originAirport);
            }

            // Determine if booking-self-button should be shown
            const isDestination = destinationAirports.some(dest => dest.id === originAirport.id);
            this.toggleButtonVisibility(buttons.bookingSelf, isDestination);
            this.toggleButtonVisibility(buttons.booking, false); // Hide booking-button on initial load

            // Hide the jumpseat button on initial load
            this.toggleButtonVisibility(buttons.jumpseat, false);
        }
    }

    /**
     * Reset the map to its initial state.
     */
    resetMapToInitialState() {
        this.deselectDestination();
        this.updateSidebarOnLoad();
    }

    /**
     * Helper method to update arrival airport information in the sidebar.
     */
    updateArrivalAirportInfo(destination) {
        if (this.sidebarElements.arrival) {
            this.sidebarElements.arrival.innerHTML = MapUtils.generateAirportInfoHTML(destination);
        }
    }

    /**
     * Helper method to hide all action buttons.
     */
    hideAllButtons() {
        const { booking, bookingSelf, jumpseat } = this.sidebarElements.buttons;
        [booking, bookingSelf, jumpseat].forEach(button => {
            this.toggleButtonVisibility(button, false);
        });
    }

    /**
     * Show loading state in the sidebar.
     */
    showLoadingState() {
        const { loadingIndicator, content, dataButtons } = this.sidebarElements;
        loadingIndicator.style.display = 'block';
        content.style.display = 'none';
        if (dataButtons) dataButtons.style.display = 'none';
    }

    /**
     * Hide loading state in the sidebar.
     */
    hideLoadingState() {
        const { loadingIndicator, content, dataButtons } = this.sidebarElements;
        loadingIndicator.style.display = 'none';
        content.style.display = 'block';
        if (dataButtons) dataButtons.style.display = 'flex'; // Use 'flex' to ensure flex layout
    }

    /**
     * Update route data in the sidebar.
     */
    updateRouteData(data) {
        const { routeDataContainer, routeData } = this.sidebarElements;
        routeDataContainer.style.display = 'grid';

        routeData.aircraftTypes.innerText = data.aircraftTypes;
        routeData.operators.innerText = data.operators;
        routeData.ete.innerText = data.ete;
        routeData.distance.innerText = data.distance;
    }

    /**
     * Hide route data in the sidebar.
     */
    hideRouteData() {
        const { routeDataContainer } = this.sidebarElements;
        routeDataContainer.style.display = 'none';
    }
}
